import store from "@/store";
import router from "@/router";
import http from "@/request/index";
import Vue from "vue";
/**
 * 主应用函数：将微应用导航path添加base前缀
 * @param {String} base path前缀
 * @param {Array} originNav 原始path
 * @returns {Array} 返回完整的导航
 */
export function _completelyMicroNav(base, originNav) {
  originNav.forEach((nav) => {
    if (nav.hasOwnProperty("path")) {
      nav.path = base + nav.path;
    } else {
      _completelyMicroNav(base, nav.children);
    }
  });
  return originNav;
}

/**
 * 主应用函数：将微应用菜单path添加base前缀
 * @param {Object} bases 微应用path前缀集合
 * @param {Array} originApps 原始微应用菜单
 * @returns {Array} 返回完整的微应用菜单
 */
export function _completelyMicroApps(bases, originApps) {
  originApps.forEach((app) => {
    app.path = bases[app.name];
  });
  return originApps;
}

/**
 * @param {String} path 获取首次加载的path,通过path判断当前激活的微应用
 * @returns {String} 返回微应用名称
 */
export function _getInitMicroAPP(path = window.location.pathname) {
  const arr = path.split("/");
  const base = arr[0] || arr[1];
  return base;
}

/**
 * @param {Array} tools 微应用顶条工具栏数据集合
 * @returns {Array} 返回加工后的工具栏数据集合，为每项工具数据添加CustomEvent实例
 */
export function _createCustomEvent(tools) {
  tools.forEach((tool) => {
    tool.event = new CustomEvent(tool.name, {
      detail: tool.data,
    });
  });
  return tools;
}

/**
 * @param {String} fullpath sessionStore的key
 */
export function _removeSessionStore(fullpath) {
  window.sessionStorage.removeItem(fullpath);
  Object.keys(window.sessionStorage).forEach((item) => {
    if (item.includes(fullpath + "*")) {
      window.sessionStorage.removeItem(item);
    }
  });
}

/**
 * @description 退出登录
 * @returns {*}
 */
function logout(safe, notoken, saveFullPath) {
  let value = {
    userId: store.state.userInfo.userId,
    path: router.history.current.fullPath,
  };
  if (value.path != "/login") {
    window.localStorage.setItem("lastExitedPage", JSON.stringify(value));
  }
  if (notoken) {
    clearCache();
    return;
  }
  return new Promise((resolve) => {
    http.get("/logout", { baseURL: "/auth" }).finally(() => {
      clearCache();
      resolve();
    });
  });
  function clearCache() {
    store.commit("setAuthorization", "");
    store.commit("setMicroRoutes", [
      {
        label: "工作台",
        labelName: "",
        fullPath: "/micro-platform",
        active: true,
      },
    ]);
    localStorage.removeItem("userInfo");
    safe && localStorage.clear();
    sessionStorage.clear();
    router.replace("/login");
  }
}
/**
 * @description token失效退出
 */
function notFound() {
  router.push({ path: "404" });
}
function notAuthority() {
  router.push({ path: "notAuthority" });
}
/**
 * @description 返回是否关闭标签
 * @param {object || boolean}
 * @property { String } step 回退步数
 * 对象传参示例：{ step: -1, close: true, backType: 'history/tag' }
 */
function goBack() {
  const [arg] = Array.from(arguments);
  const fullPath = router.history.current.fullPath;
  // 默认值
  let [step, isClose, backType] = [-1, true, "tag"];
  if (typeof arg === "object") {
    // 配置项
    step = arg.step || step;
    isClose = arg.close || isClose;
    backType = arg.backType || backType;
  } else {
    // 布尔值
    isClose = arg || isClose;
  }
  store.commit("removeCurrentRoute", { isClose, fullPath });
  _removeSessionStore(fullPath);
}

function closeTagToPath(toPath) {
  const fullPath = router.history.current.fullPath;
  store.commit("removeCurrentRoute", { fullPath, toPath });
  sessionStorage.removeItem(fullPath);
  sessionStorage.removeItem("Extend-" + fullPath);
}

/**
 * @description 关闭当前页面
 */
function closeCurrentTab() {
  let activeRoute = store.state.microRoutes.find((item) => item.active);
  store.commit("removeRoutes", { route: activeRoute });
}

const cacheMixin = {
  data() {
    return {
      cross: true, //跨系统切路由不会走beforeRouteLeave
      sessionKey: "",
      cacheData: null,
      _usedCacheData: false,
    };
  },
  created() {
    this.sessionKey =
      (this._routerKey || this.$router.options.base + this.$route.fullPath) +
      (this.cacheConfig.componentKey ? this.cacheConfig.componentKey : "");
    this.cacheData = JSON.parse(sessionStorage.getItem(this.sessionKey));
    this.cacheConfig.autoReverse && this._reverseCacheData(); //组件可以配置自动反显缓存的数据
  },

  beforeRouteLeave(to, from, next) {
    if (this._routeExist) {
      sessionStorage.setItem(this.sessionKey, JSON.stringify(this._originData));
    }
    this.cross = false;
    next();
  },
  beforeDestroy() {
    if (this.cross && this._routeExist) {
      sessionStorage.setItem(this.sessionKey, JSON.stringify(this._originData));
    }
  },
  computed: {
    //获取_routerKey
    _routerKey() {
      let queryArr = [];
      if (this.$route.meta.keys?.length) {
        this.$route.meta.keys.forEach((key) => {
          queryArr.push(`${key}=${this.$route.query[key] || this.$route.params[key]}`);
        });
      }
      const proxyName = this.$route.meta?.proxyName; // 手动写入的页面标记
      let routeKey = "";
      if (proxyName) {
        routeKey = `${router.options.base}${proxyName}${
          queryArr.length ? "?" + queryArr.join("&") : ""
        }`;
      } else {
        routeKey = router.options.base + this.$route.name;
        if (queryArr.length) {
          routeKey = `${routeKey}?${queryArr.join("&")}`;
        } else {
          routeKey = 0;
        }
      }
      return this.cacheConfig?.isRouterKey ? routeKey : "";
    },
    //判断当前路由是否仍然存在
    _routeExist() {
      return this.$mainStore.state.microRoutes.some(
        (item) =>
          (this._routerKey || item.fullPath) === this.sessionKey ||
          this.sessionKey.includes(item.fullPath + "*"),
      );
    },
    //通过缓存配置组装要缓存的数据
    _originData() {
      const data = {};
      this.cacheConfig.keys.forEach((key) => {
        data[key] = this[key];
      });
      return data;
    },
  },
  watch: {
    //监听路由参数变化的一套缓存机制（不走created）
    $route(to, from) {
      if (this.cross && this._routeExist) this._setSession(this.sessionKey);
      this.sessionKey =
        (this._routerKey || this.$router.options.base + to.fullPath) +
        (this.cacheConfig.componentKey ? this.cacheConfig.componentKey : "");
      this._getSession(this.sessionKey);
    },
  },
  methods: {
    _setSession(sessionKey) {
      const data = {};
      this.cacheConfig.keys.forEach((key) => {
        data[key] = this[key];
      });
      sessionStorage.setItem(sessionKey, JSON.stringify(data));
    },
    _getSession(sessionKey) {
      this.cacheData = JSON.parse(sessionStorage.getItem(sessionKey));
      if (this.cacheData) {
        this.cacheConfig.keys.forEach((key) => {
          this[key] = this.cacheData[key];
        });
      }
    },
    _reverseCacheData() {
      if (this.cacheData) {
        this.cacheConfig.keys.forEach((key) => {
          this[key] = this.cacheData[key];
        });
        this._usedCacheData = true;
      }
    },
  },
};

const directives = {
  "layout-flex": {
    bind(el, binding) {
      //初始化父元素样式
      el.style.display = "flex";
      el.style.justifyContent = "flex-start";
      el.style.flexWrap = "wrap";

      //生成并插入工具节点
      el.HB_LAYOUT_TOOLNODE = document.createElement("div");

      if (binding.modifiers.end) {
        el.insertBefore(el.HB_LAYOUT_TOOLNODE, el.children[el.children.length - 1]);
      }

      //计算子节点和工具节点的宽度
      el.HB_LAYOUT_HANDLER = function (el, binding) {
        //计算子元素的宽度和插入工具节点的个数
        let clientWidth = el.clientWidth - 8; //总宽度
        let minWidth = binding.value?.minWidth || 280; //子元素最小宽度
        let gutter = binding.value?.gutter || 20; //子元素之间的间距
        let num = Math.floor((clientWidth + gutter) / (minWidth + gutter)) || 1; //列数
        let nodeNum = binding.value?.nodeNum || el.children.length;
        if (num > nodeNum && !binding.modifiers.end) {
          //非end模式下列数大于子元素个数时，不再设置工具节点的宽度
          num = nodeNum;
        }
        let width = Math.floor((clientWidth - (num - 1) * gutter) / num); //子元素最终宽度

        //非end模式下支持maxWidth,end模式不会无限拉宽的，过多的宽度会给到工具节点
        if (!binding.modifiers.end && binding.value?.maxWidth && binding.value?.maxWidth < width) {
          width = binding.value.maxWidth;
        }

        let colspan = num - ((el.children.length - 1) % num); //工具节点所占列数
        colspan === num ? (colspan = 0) : null;
        //设置子节点宽度和右边距
        Array.from(el.children).forEach((item, index, arr) => {
          item.style.width = width + "px";
          if ((index + 1) % num && index !== arr.length - 1) {
            item.style.marginRight = gutter + "px";
          } else {
            item.style.marginRight = "0px";
          }
          //独占一行的子元素
          if (binding.value.nodeNum) {
            if (index >= binding.value.nodeNum) {
              item.style.marginRight = "0px";
              item.style.width = "100%";
            }
          }
        });
        //end模式下设置工具节点宽度
        if (colspan && binding.modifiers.end) {
          el.HB_LAYOUT_TOOLNODE.style.display = "block";
          el.HB_LAYOUT_TOOLNODE.style.width = colspan * width + (colspan - 1) * gutter + "px";
        } else {
          el.HB_LAYOUT_TOOLNODE.style.display = "none";
        }
      }.bind(null, el, binding);
    },
    inserted(el) {
      el.HB_LAYOUT_HANDLER();
      window.addEventListener("resize", el.HB_LAYOUT_HANDLER);
    },
    componentUpdated(el) {
      el.HB_LAYOUT_HANDLER();
    },
    unbind(el) {
      window.removeEventListener("resize", el.HB_LAYOUT_HANDLER);
    },
  },
  "layout-sub-flex": {
    bind(el, binding) {
      el.HB_LAYOUT_TRULY_NODE = binding.value?.el ? el.querySelector(binding.value.el) : el;
      el.HB_LAYOUT_TRULY_NODE.style.display = "flex";
      el.HB_LAYOUT_TRULY_NODE.style.clear = "both"; //解决label浮动的影响

      el.HB_LAYOUT_TRULY_NODE.style.alignItems = "center";

      Array.from(el.HB_LAYOUT_TRULY_NODE.children).forEach((item, index) => {
        if (index) {
          item.style.flexShrink = 0;
          item.style.marginLeft = (binding.value?.gutter || 10) + "px";
          item.style.width = binding.value?.width[index - 1]
            ? binding.value?.width[index - 1] + "px"
            : "auto";
        } else {
          item.style.flexShrink = 1;
        }
      });
    },
  },
  // 表格左下角的小计部分的左偏移量
  "sub-total-left": function (el, bind, vnode) {
    // bind.value  小计元素与共*条元素的间距
    vnode.context.$nextTick(() => {
      let hasScroll = el.scrollWidth > el.clientWidth; // 判断元素是否有横向滚动条，以此获取要偏移的bottom量
      let bottom = hasScroll ? "0px" : "5px";
      let width = vnode.context.$el
        .querySelector(".el-pagination__total")
        ?.getBoundingClientRect().width;
      el.style.width = "calc(100% - 650px)";
      el.style.height = "24px";
      el.style.display = "flex";
      el.style.flexWrap = "nowrap";
      el.style.alignItems = "center";
      el.style.overflow = "auto";
      el.style.whiteSpace = "nowrap";
      el.style.left = width + bind.value + 18 + "px";
      el.style.position = "absolute";
      el.style.bottom = bottom;
      el.style.fontSize = "12px";
    });
  },
};
function $router() {
  return router;
}
export const mainMethods = {
  logout,
  notFound,
  notAuthority,
  goBack,
  closeCurrentTab,
  closeTagToPath,
  cacheMixin,
  directives,
  $router,
};
