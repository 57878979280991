//第三方库
import Vue from "vue";
import http from "./request/index";
import router from "./router";
import md5 from "js-md5";
import Rules from "rules";

//本地模块
import App from "./app";
import store from "./store";
import "@/assets/iconfont/iconfont.css";
import "@/assets/symbol/iconfont.css";
import "@/assets/symbol/iconfont.js";
import config from "./assets/js/rulesConfig.js";
import "@/assets/styles/global.css";
import { mainMethods } from "@/assets/js/globalUtils.js";
//element-ui
import elementUi from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Object.defineProperties(elementUi.Select.props, {
  clearable: {
    value: { default: true, type: Boolean },
  },
});
Vue.use(elementUi);
Vue.prototype.$md5 = md5;
Vue.prototype.$http = http;
Vue.prototype.$mainRules = Rules; // 挂载类工具到vue
Vue.prototype.$mainFormRules = new Rules(config).rules; // FromRules
import hbteui from "hbte-saas-ui";
if (hbteui.ENV === "production") {
  //避免本地link hbte-saas-ui时重复引用css,hbteui.ENV是打包时注入的变量
  //用来区分当前引用的是hbui源码还是打包后的代码
  import("hbte-saas-ui/lib/hbte-ui.css");
}
Vue.directive("layout-flex", mainMethods.directives["layout-flex"]); // 任务组件详情页面、hbUpload组件要使用
Vue.use(hbteui);
//主应用实例化
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#mainApp");

//捕获微服务连接失败的异常，避免在开发阶段因为没有启动所有微服务而大量报错
window.onunhandledrejection = (event) => {
  let reason = event.reason?.message;
  let reg = /Failed to fetch/i;
  if (reg.test(reason)) {
    console.warn("microApp connection failed!");
  }
  event.preventDefault();
};
